<template>
  <div>
    <div style="margin-bottom: 10px" class="custom-flex custom-justify-between">
      <div style="display: flex">
        <a-button type="primary" @click="settlementSelectedOtherItems" html-type="button" v-if="isShowButton">
          标记为已支付
        </a-button>
        <a-button style="margin-left: 10px" @click="printPdf" type="primary" html-type="button" v-if="isShowButton">
          打印勾选项
        </a-button>
      </div>
      <span style="color:green">(不包含已取消订单)金额：{{ totalFee | formatCurrency }}元</span>
    </div>

    <a-table
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="id"
      :scroll="{x:1000,y:350}"
    >
      <div slot="payment_method" slot-scope="payment_method, record">
        <span>{{ record.payment_status ? payment_method: '-' }}</span>
      </div>
      <div slot="voucher_fee" slot-scope="voucher_fee, record">
        <span>{{ record.payment_status ? voucher_fee: '-' }}</span>
      </div>
      <div slot="payment_fee" slot-scope="payment_fee, record">
        <span>{{ record.payment_status ? payment_fee: '-' }}</span>
      </div>
      <div slot="payment_at" slot-scope="payment_at, record">
        <span>{{ record.payment_status ? payment_at: '-' }}</span>
      </div>
      <a-table
        slot="expandedRowRender"
        slot-scope="record"
        :columns="childrenColumns"
        :data-source="record.service_order_other_product_items"
        :pagination="false"
        row-key="id"
        :row-selection="{ selectedRowKeys: serviceOrderOtherItemIds, onChange: onSelectChange }"
      >
        <div slot="effective" slot-scope="effective">
          <span>{{ effective ? '有效': '失效' }}</span>
        </div>
        <div slot="is_settled" slot-scope="is_settled">
          <span>{{ is_settled ? '已支付': '未支付' }}</span>
        </div>
      </a-table>
    </a-table>
  </div>
</template>

<script>
import { findOrderInfo, findServiceOrderOtherItems, settlementOtherItems, settlementOtherItemsPDF } from '@/api/order'
import printJS from 'print-js'

export default {
  name: 'ProjectSummary',
  components: {
  },
  data() {
    return {
      data: {},
      tableData: [],
      totalFee: 0,
      serviceOrderOtherItemIds: [],
      serviceOrderId: 0,
      serviceOrder: {},
      isShowButton: false
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          title: '项目编号',
          dataIndex: 'order_no',
          width: 150
        },
        {
          title: '项目来源',
          dataIndex: 'source',
          width: 150
        },
        {
          title: '物品件数',
          dataIndex: 'sub_count',
          width: 150
        },
        {
          title: '项目金额(元)',
          dataIndex: 'sub_fee_display',
          width: 150
        },
        {
          title: '支付方式',
          dataIndex: 'payment_way',
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'payment_status_display',
          width: 150
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 200
        },
        {
          title: '支付单号',
          dataIndex: 'payment_no',
          width: 150
        },
        {
          title: '支付途径',
          dataIndex: 'payment_method',
          width: 200,
          scopedSlots: { customRender: 'payment_method' }
        },
        {
          title: '优惠金额(元)',
          dataIndex: 'voucher_fee',
          width: 200,
          scopedSlots: { customRender: 'voucher_fee' }
        },
        {
          title: '实际支付金额(元)',
          dataIndex: 'payment_fee',
          width: 200,
          scopedSlots: { customRender: 'payment_fee' }
        },
        {
          title: '支付时间',
          dataIndex: 'payment_at',
          width: 250,
          scopedSlots: { customRender: 'payment_at' }
        }
      ]
    },
    childrenColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'id',
          width: 100
        },
        {
          title: '品名',
          dataIndex: 'product_name',
          width: 100
        },
        {
          title: '规格',
          dataIndex: 'product_specification_name',
          width: 100
        },
        {
          title: '价格',
          dataIndex: 'price',
          width: 100
        },
        {
          title: '数量',
          dataIndex: 'sub_count',
          width: 100
        },
        {
          title: '金额(元)',
          dataIndex: 'sub_fee_display',
          width: 100
        },
        {
          title: '支付情况',
          dataIndex: 'settled_info',
          width: 100
        }
      ]
    }
  },
  created() {
    this.fetchData()
    this.fetchServiceOrder()
  },
  methods: {
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable) return null
      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },

    onSelectChange(selectedRowKeys) {
      this.serviceOrderOtherItemIds = selectedRowKeys
    },

    fetchServiceOrder() {
      findOrderInfo(this.id).then(res => {
        if (res.code === 0) {
          this.serviceOrder = res.data
          this.isShowButton = !['unpaid', 'unconfirmed'].includes(this.serviceOrder.order_status_slug)
        }
      })
    },

    fetchData() {
      findServiceOrderOtherItems(this.id).then((res) => {
        this.tableData = res.data.data
        this.serviceOrderId = res.data.service_order_id
        this.totalFee = res.data.total_fee
      })
    },

    settlementSelectedOtherItems() {
      if (this.serviceOrderOtherItemIds.length === 0) {
        this.$warning({
          title: '请选择操作对象'
        })
        return
      }

      settlementOtherItems(this.serviceOrderId, { other_item_ids: this.serviceOrderOtherItemIds }).then(res => {
        if (res.code === 0) {
          this.serviceOrderOtherItemIds = []
          this.fetchData()
        }
      })
    },

    printPdf() {
      if (this.serviceOrderOtherItemIds.length === 0) {
        this.$warning({
          title: '请选择操作对象'
        })
        return
      }
      settlementOtherItemsPDF(this.serviceOrderId, { other_item_ids: this.serviceOrderOtherItemIds }).then(res => {
        if (res.code === 0) {
          this.serviceOrderOtherItemIds = []
          if (res.data.url) {
            this.$info({
              title: '正在生成结算PDF文件，请耐心等候',
              content: ''
            })
            printJS(res.data.url)
          }
        }
      })
    }
  }
}
</script>

