<template>
  <div>
    <a-row style="text-align: right; margin-bottom: 10px">
      <span style="color:green">金额：{{ totalFee | formatCurrency }}元</span>
    </a-row>

    <a-table
      style="overflow: auto"
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="id"
    >
      <a-table
        slot="expandedRowRender"
        slot-scope="record"
        :columns="childrenColumns(record)"
        :data-source="record.service_order_other_product_items"
        :pagination="false"
        row-key="id"
      >
        <span slot="elegiac_information" slot-scope="text, record">
          <a-space>
            <a @click="showElegiacInformationModal(record)">点击查看</a>
          </a-space>
        </span>
      </a-table>
    </a-table>
    <show-elegiac-information
      v-if="isShowElegiac"
      :visible.sync="isShowElegiac"
      :elegiac-information="elegiacInformation"
    />
  </div>
</template>

<script>
import { findProjectSummary } from '@/api/order'

export default {
  name: 'ProjectSummary',
  components: {
    ShowElegiacInformation: () => import('@/views/orders/tenant/ElegiacInformation')
  },
  data() {
    return {
      data: {},
      orderId: 0,
      orderType: 'service',
      isShowElegiac: false,
      tableData: [],
      elegiacInformation: {},
      totalFee: 0
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'id'
        },
        {
          title: '品名',
          dataIndex: 'name'
        },
        {
          title: '类型',
          dataIndex: 'project_type_display'
        },
        {
          title: '数量',
          dataIndex: 'left_count'
        },
        {
          title: '金额(元)',
          dataIndex: 'project_fee_display'
        }
      ]
    },
    normalColumns() {
      return [
        {
          title: '规格',
          dataIndex: 'product_specification_name'
        },
        {
          title: '价格',
          dataIndex: 'price_display'
        },
        {
          title: '数量',
          dataIndex: 'left_count'
        },
        {
          title: '金额(元)',
          dataIndex: 'fee'
        },
        {
          title: '项目编号',
          dataIndex: 'project_no'
        },
        {
          title: '订购时间',
          dataIndex: 'created_at'
        }
      ]
    },
    wreathColumns() {
      return [
        {
          title: '规格',
          dataIndex: 'product_specification_name'
        },
        {
          title: '价格',
          dataIndex: 'price_display'
        },
        {
          title: '数量',
          dataIndex: 'left_count'
        },
        {
          title: '金额(元)',
          dataIndex: 'fee'
        },
        {
          title: '项目编号',
          dataIndex: 'project_no'
        },
        {
          title: '订购时间',
          dataIndex: 'created_at'
        },
        {
          title: '挽联信息',
          dataIndex: 'elegiac_information',
          scopedSlots: { customRender: 'elegiac_information' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    childrenColumns(childrenRecord) {
      if (childrenRecord.project_type === 'wreath') {
        return this.wreathColumns
      } else {
        return this.normalColumns
      }
    },
    showElegiacInformationModal(record) {
      this.elegiacInformation = record.moreInfo
      this.isShowElegiac = true
    },
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable) return null
      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },

    fetchData() {
      findProjectSummary(this.id).then((res) => {
        this.tableData = res.data.data
        this.totalFee = res.data.total_fee
      })
    }
  }
}
</script>

